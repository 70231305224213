import wordmarkDark from "./wordmarkDark.svg";
import wordmarkLight from "./wordmarkLight.svg";
import styles from "./Wordmark.module.css";
import classNames from "classnames";
import { useContext } from "react";
import { Theme, ThemeContext } from "../../themes/Theme";

type WordmarkProps = {
  onClick?: () => void;
  className?: string;
  height?: string;
  themeOverride?: Theme;
};

export const Wordmark: React.FC<WordmarkProps> = ({
  className,
  onClick,
  height = "40px",
  themeOverride,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = themeOverride
    ? themeOverride === "light"
    : theme === "light";

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyDown={(event) => {
        if ((event.key === "Enter" || event.key === " ") && onClick) {
          onClick();
        }
      }}
    >
      <img
        src={isLightMode ? wordmarkLight : wordmarkDark}
        className={classNames(styles.wordmark, className && className)}
        style={{ height: height }}
      />
    </div>
  );
};
